import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';

const PaletteButton = ({ className, onTap, isActive }) => (
  <Button
    className={className}
    onTap={onTap}
    isActive={isActive}
    name="palette"
    viewBox="0 0 20 12"
    noBackground
    svg
  >
    <path d="M10.1133 0.00072722C7.60003 0.00072722 3.8048 0.715636 3.8048 2.38764C3.8048 3.77673 5.79362 4.73673 5.04781 5.21673C4.29759 5.70036 3.07443 3.87127 2.0712 3.87127C0.854663 3.87127 0 4.91491 0 6C0 9.31418 4.65284 12 10.1133 12C15.573 12 20 9.31418 20 6C20 2.68655 15.5722 0 10.1125 0L10.1133 0.00072722ZM6.18197 4.56073C6.18197 4.09236 6.83436 3.71345 7.63828 3.71345C8.44219 3.71345 9.09459 4.09164 9.09459 4.56145C9.09459 5.03055 8.44219 5.41018 7.63828 5.41018C6.83436 5.41018 6.18197 5.02982 6.18197 4.56218V4.56073ZM9.1093 10.9462C8.33186 10.9462 7.70227 10.4516 7.70227 9.84073C7.70227 9.22982 8.33186 8.73745 9.11003 8.73745C9.88673 8.73745 10.5171 9.232 10.5171 9.84145C10.5171 10.4509 9.88673 10.9469 9.1093 10.9469V10.9462ZM13.0244 10.5607C12.2477 10.5607 11.6181 10.0662 11.6181 9.45673C11.6181 8.84582 12.2477 8.35127 13.0252 8.35127C13.8048 8.35127 14.4337 8.84582 14.4337 9.45673C14.4337 10.0676 13.8048 10.5622 13.0259 10.5622L13.0244 10.5607ZM13.3664 2.48073C13.3664 1.87127 13.996 1.37673 14.7742 1.37673C15.5524 1.37673 16.1805 1.87127 16.1805 2.48073C16.1805 3.09164 15.5516 3.58473 14.7735 3.58473C13.9968 3.58473 13.365 3.09018 13.365 2.48073H13.3664ZM16.4556 8.89673C15.6781 8.89673 15.0478 8.40218 15.0478 7.79127C15.0478 7.18036 15.6789 6.688 16.4563 6.688C17.236 6.688 17.8641 7.18254 17.8641 7.792C17.8641 8.40145 17.2345 8.89745 16.4556 8.89745V8.89673ZM17.1984 5.98255C16.421 5.98255 15.7914 5.488 15.7914 4.87709C15.7914 4.26836 16.421 3.77382 17.1992 3.77382C17.9766 3.77382 18.6062 4.26836 18.6062 4.87782C18.6062 5.48873 17.9759 5.98327 17.1984 5.98327V5.98255Z" />
  </Button>
);

PaletteButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onTap: PropTypes.func,
};

PaletteButton.defaultProps = {
  className: '',
  onTap: null,
};

export default PaletteButton;
