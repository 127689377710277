import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';

const SettingsButton = ({ onTap, tooltipText }) => (
  <Button
    name="check"
    viewBox="0 0 20 20"
    onTap={onTap}
    tooltipText={tooltipText}
    svg
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.14 10.94C17.18 10.64 17.2 10.33 17.2 10C17.2 9.68003 17.18 9.36003 17.13 9.06003L19.16 7.48003C19.2475 7.40793 19.3073 7.30772 19.3292 7.19649C19.351 7.08527 19.3337 6.96989 19.28 6.87003L17.36 3.55003C17.3035 3.44959 17.2132 3.3724 17.1053 3.33214C16.9973 3.29187 16.8785 3.29112 16.77 3.33003L14.38 4.29003C13.88 3.91003 13.35 3.59003 12.76 3.35003L12.4 0.810027C12.3823 0.695557 12.3241 0.591234 12.2361 0.516003C12.148 0.440772 12.0359 0.399623 11.92 0.400027H8.08003C7.84003 0.400027 7.65002 0.570027 7.61002 0.810027L7.25003 3.35003C6.66003 3.59003 6.12003 3.92003 5.63003 4.29003L3.24003 3.33003C3.02003 3.25003 2.77003 3.33003 2.65003 3.55003L0.740025 6.87003C0.620025 7.08003 0.660025 7.34003 0.860025 7.48003L2.89003 9.06003C2.84003 9.36003 2.80003 9.69003 2.80003 10C2.80003 10.31 2.82003 10.64 2.87003 10.94L0.840025 12.52C0.752549 12.5921 0.692779 12.6923 0.670898 12.8036C0.649016 12.9148 0.666378 13.0302 0.720025 13.13L2.64003 16.45C2.76003 16.67 3.01003 16.74 3.23003 16.67L5.62003 15.71C6.12003 16.09 6.65003 16.41 7.24003 16.65L7.60003 19.19C7.65003 19.43 7.84003 19.6 8.08003 19.6H11.92C12.16 19.6 12.36 19.43 12.39 19.19L12.75 16.65C13.34 16.41 13.88 16.09 14.37 15.71L16.76 16.67C16.98 16.75 17.23 16.67 17.35 16.45L19.27 13.13C19.39 12.91 19.34 12.66 19.15 12.52L17.14 10.94V10.94ZM10 13.6C8.02002 13.6 6.40002 11.98 6.40002 10C6.40002 8.02003 8.02002 6.40003 10 6.40003C11.98 6.40003 13.6 8.02003 13.6 10C13.6 11.98 11.98 13.6 10 13.6Z"
    />
  </Button>
);

SettingsButton.propTypes = {
  onTap: PropTypes.func.isRequired,
  tooltipText: PropTypes.string,
};

SettingsButton.defaultProps = {
  tooltipText: '',
};
export default SettingsButton;
